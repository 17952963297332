import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import './element-variables.scss'
Vue.use(ElementUI);
import './base.css'
import './main.less'
import md5 from 'js-md5';
import router from './router'
import config from './config'


import http from '@/plugins/http.js' //axios实例化后引入取名http
Vue.prototype.http = http //放入全局
Vue.prototype.domain = http.domain; //放入全局
window.domain=http.domain;
Vue.prototype.$md5 = md5

Vue.prototype.bus = new Vue();
Vue.config.productionTip = false;

Vue.prototype.$config = config; //放入全局

import common from '@/plugins/common';
Vue.prototype.$common = common
// // 新手引导
// import Driver from 'driver.js';
// import 'driver.js/dist/driver.min.css'
// // Vue.prototype.$driver = new Driver()
// Vue.prototype.$driver = new Driver({
//   doneBtnText: '完成',              // Text on the final button
//   closeBtnText: '关闭',            // Text on the close button for this step
//   stageBackground: 'transparent',       // Background color for the staged behind highlighted element
//   nextBtnText: '下一步',              // Next button text for this step
//   prevBtnText: '上一步',          // Previous button text for this step
// })

import VueIntro from 'vue-introjs';
import 'intro.js/introjs.css';
Vue.use(VueIntro);

new Vue({
    router,
    render: h => h(App),
    data: function() {
        return {
            userInfo: {
                ext_time:{},
                service:{}
            },
            selectData: [],
            count: {},
            nextParson:[]
        }
    }
}).$mount('#app');
