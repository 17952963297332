import axios from 'axios'
import {
    Message,
    Loading
} from 'element-ui';
import router from '../router'
import $config from '../config'

axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
var domain = $config.http_request_url[process.env.NODE_ENV]
var _token = window.sessionStorage.getItem('_token');
// console.log(_token);
var service = axios.create({
    baseURL: domain,
    headers: {
        'content-type': 'application/json',
        'X-CSRF-TOKEN': _token
    }
})
export default {
    domain: domain,
    // formBinary是否二进制传参，默认否；
    // load是否显示加载中，默认是
    post(url, param, load = true,isShowCode=false) {
        let loadingInstance;
        if (load) {
            loadingInstance = Loading.service({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'myLoading',
            });
        }
        return new Promise((cback, reject) => {
            service({
                method: 'post',
                url,
                // data: formBinary?param:JSON.stringify(param)
                data: param
            }).then(res => {
                if (load) {
                    loadingInstance.close();
                }
                //axios返回的是一个promise对象
				if ((_token != res.data._token) && res.data._token) window.sessionStorage.setItem('_token',res.data._token);
				var res_code = res.data.code.toString();
				if(isShowCode){
					cback(res.data);
				}else{
					if (res_code == 200) {
						cback(res.data.data); //cback在promise执行器内部
					// }else if(res_code == 204){
					// 	cback(res.data.data); //cback在promise执行器内部
					// 	Message({
					// 		showClose: true,
					// 		message: res.data.message,
					// 		type: 'error'
					// 	});
					// 	router.push('/payment');
					} else if (res_code == 201||res_code == 202||res_code == 203||res_code == 205) {
						Message({
							showClose: true,
							message: res.data.message,
							type: 'error'
						});
						router.push('/');
						window.sessionStorage.removeItem('_token')
					} else {
						Message({
							showClose: true,
							message: res.data.message,
							type: 'error'
						});
					}
				}
            }).catch(err => {
                if (load) {
                    loadingInstance.close();
                }
                if (!err.response) {
                    console.log('请求错误')
                    Message({
                        showClose: true,
                        message: '请求错误',
                        type: 'error'
                    });
                } else {
                    reject(err.response);
                    console.log(err.response, '异常')
                }
            })

        })
    },
}
