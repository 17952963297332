import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default new Router({
    routes: [{
        path: '/',
        name: '登录',
        component: () => import('./views/login.vue')
    },{
        path: '/updataShow',
        name: '更新日志',
        component: () => import('./views/updataShow.vue')
    },{
        path: '/register',
        name: '注册',
        component: () => import('./views/register.vue')
    },{
		path: '/headquartersIndex',
        name: '首页',
        component: () => import('./views/management/index.vue'),
		children:[{
			path: '/headquartersMerchant',
            name: '商户管理',
            component: () => import('./views/management/merchant.vue'),
		},{
			path: '/headquartersCharge',
            name: '商户管理-服务费记录',
            component: () => import('./views/management/charge.vue'),
		},{
			path: '/headquartersCategory',
            name: '收费类别',
            component: () => import('./views/management/category.vue'),
		},{
			path: '/headquartersPeople',
            name: '人员管理',
            component: () => import('./views/management/people.vue'),
		},{
			path: '/headquartersUpdateDate',
            name: '更新日志',
            component: () => import('./views/management/updateDate.vue'),
		}]
	},{
        path: '/index',
        name: '首页',
        component: () => import('./views/index.vue'),
        children: [{
            path: '/overview',
            name: '可视化统计',
            component: () => import('./views/overview.vue'),
        },{
            path: '/tree',
            name: '测试',
            component: () => import('./views/tree.vue'),
        },{
            path: '/overPreview',
            name: '可视化统计详情',
            component: () => import('./views/overPreview.vue'),
        },{
            path: '/week',
            name: '周报',
            component: () => import('@/views/week.vue')
        },{
            path:'/month',
            name: '月报',
            component: () => import('@/views/month.vue')
        },{
            path: '/admin',
            name: '岗位职责',
            component: () => import('./views/setting/admin.vue'),
        }]
    },{
        path: '/setting',
        name: '后台',
        component: () => import('./views/setting/index.vue'),
        children: [{
            path: '/organization',
            name: '组织信息',
            component: () => import('./views/setting/organization.vue'),
        },{
            path: '/relationship',
            name: '汇报关系',
            component: () => import('./views/setting/relationship.vue'),
        },{
            path: '/common',
            name: '系统设置',
            component: () => import('./views/setting/common.vue'),
        },{
            path: '/meet',
            name: '开会区间',
            component: () => import('./views/setting/meet.vue'),
        },{
            path: '/department',
            name: '部门岗位',
            component: () => import('./views/setting/department.vue'),
        },{
            path: '/stopUser',
            name: '停用人员',
            component: () => import('./views/setting/stopUser.vue'),
        },{
            path: '/statement',
            name: '系统说明',
            component: () => import('./views/setting/statement.vue'),
        },{
            path: '/payment',
            name: '缴费管理',
            component: () => import('./views/setting/payment.vue'),
        },{
            path: '/classify',
            name: '工作类别',
            component: () => import('./views/setting/classify.vue'),
        },{
            path: '/admin2',
            name: '工作类别',
            component: () => import('./views/setting/admin.vue'),
        }]
    }]
})
