export default {
    // ---------------------------中国标准时间 转 年月日start----------------------------------------
    timeFormatter(time, type) { //type=year返回年 month返回月 不传返回天
        if (time) {
            const d = new Date(time);
            let timeString;
            if (type == 'year') {
                timeString = `${this.timeFormat(d.getFullYear())}`;
            } else if (type == 'month') {
                timeString = `${this.timeFormat(d.getFullYear())}-${this.timeFormat(d.getMonth() + 1)}`;
            } else {
                timeString = `${this.timeFormat(d.getFullYear())}-${this.timeFormat(d.getMonth() + 1)}-${this.timeFormat(d.getDate())}`;
            }
            return timeString
        } else {
            return ''
        }
    },
    timeFormat(val) {
        return val < 10 ? `0${val}` : val;
    },
    // ---------------------------中国标准时间 转 年月日end----------------------------------------
    //&&&&&&&&&&&&&&&&

    // ---------------------------获取当前月、周的第一天、最后一天 （年月日）start----------------------------------------
    getFirstLastDay(type) { //type=month返回月 week返回周
        let current = new Date();
        let currentyear = current.getFullYear();
        let currentMonth = current.getMonth();
        let currentDay = current.getDay();
        let currentDate = current.getDate();
        if (type == 'month') {
            var month = new Date(currentyear, currentMonth, 1);
            var monthFirstDay = new Date(currentyear, currentMonth, 1)
            var monthLastDay = new Date(monthFirstDay.getFullYear(), currentMonth + 1, 0);
            return [this.timeFormatter(monthFirstDay), this.timeFormatter(monthLastDay)]
        } else if (type == 'week') {
            var weekFirstDay = new Date(currentyear, currentMonth, currentDate - currentDay + 1);
            var weekLastDay = new Date(currentyear, currentMonth, currentDate + (6 - currentDay + 1));
            return [this.timeFormatter(weekFirstDay), this.timeFormatter(weekLastDay)]
        }
    },

    getPreDate(type) { //type=month返回月 week返回周
        let current = new Date();
        if (type == 'week') {
            var timestamp = (current.getTime() / 1000) - 24 * 60 * 60 * 7;
            var year = new Date(timestamp*1000).getFullYear();
            var month = new Date(timestamp*1000).getMonth() + 1;
            var day = new Date(timestamp*1000).getDay()+ 1;
            return `${year}-${this.timeFormat(month)}-${this.timeFormat(day)}`
        } else if (type == 'month') {
            var timestamp = current.getTime();
            var year = new Date(timestamp).getFullYear();
            var month = new Date(timestamp).getMonth();
            var day = new Date(timestamp).getDay();
            console.log(`${year}-${this.timeFormat(month)}-${this.timeFormat(day)}`);
            return `${year}-${this.timeFormat(month)}-${this.timeFormat(day)}`
        }
    },
    // ---------------------------获取当前月、周的第一天、最后一天 （年月日）end------------------------------------------

    // 获取地址栏参数
    getUrlParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },
}
